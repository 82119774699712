import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "./game.css";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";

function Sports() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [isLoading, setLoading] = useState(false);
    const [game, setGame] = useState(null);
    const [title, setTitle] = useState(null);
    const [isShow, setShow] = useState(false);
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
        handleOptionClick("0");
    }, []);
    const [activeOption, setActiveOption] = useState(null);
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    function isMobileDevice() {
        if (window.innerWidth < 800) {
            return true;
        }
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            setProfile(res.data.data);
            localStorage.setItem("profile", JSON.stringify(res.data.data));
        });
    }, []);
    function launchGame(params) {
        if (localStorage.getItem("profile") == null || localStorage.getItem("profile") == "undefined") {
            navigate("/login");
            return;
        }
        if (isLoading == true) return;
        const form = {
            ...params,
            platform: isMobileDevice == true ? "html5" : "html5-desktop"
        };
        setLoading(true);
        let a;
        switch (params.product_type) {
            case "4":
                a = "AG";
                break;
            case "79":
                a = "BBIN";
                break;
            case "104":
                a = "CMD";
                break;
            case "16":
                a = "CQ9";
                break;
            case "27":
                a = "DG";
                break;
            case "86":
                a = "Evolution";
                break;
            case "142":
                a = "FB";
                break;
            case "140":
                a = "JILI";
                break;
            case "121":
                a = "KingMaker";
                break;
            case "102":
                a = "V8 Poker";
                break;
            case "123":
                a = "King Poker";
                break;
            case "43":
                a = "MG";
                break;
            case "39":
                a = "PP";
                break;
            case "174":
                a = "SABA";
                break;
            case "118":
                a = "WM";
                break;
            case "112":
                a = "SEXY";
                break;
            case "54":
                a = "SBO";
                break;
            case "151":
                a = "UG";
                break;
            case "99":
                a = "TF";
                break;
            case "111":
                a = "Playtech";
                break;
            case "28":
                a = "AllBet";
                break;
            case "55":
                a = "JDB";
                break;
            case "202":
                a = "WS168";
                break;
            case "61":
                a = "IMES";
                break;
            case "132":
                a = "SV388";
                break;
            default:
                a = "";
                break;
        }
        setSanh(a);
        setProduct(params.product_type);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/launchgame`, form)
            .then((res) => {
                if (res.data.data.status == 0) {
                    setPlayGame(res.data.data.game_url);
                    axios
                        .post(`${process.env.REACT_APP_API_URL}/casino/getbalance`, { product_type: params.product_type })
                        .then((res) => {
                            setBalance(res.data.data.balance);
                            setLoading(false);
                        })
                        .catch((err) => swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error"));
                } else {
                    setLoading(false);
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.data.data.tokenVerificationData.access == false) {
                    swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error");
                    return;
                }
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            });
    }
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    const [playGame, setPlayGame] = useState(null);
    const { register, handleSubmit } = useForm();
    const [newMoney, setNewMoney] = useState(null);
    const [balance, setBalance] = useState(0);
    const [sanh, setSanh] = useState(null);
    const [product, setProduct] = useState(null);
    const [type, setType] = useState(null);
    const [load, setLoad] = useState(false);
    const onSubmit = (data) => {
        if (load === false) {
            if (!sanh) {
                swal("Thông báo", "Vui lòng chọn sảnh", "warning");
                return;
            }
            const formData = {
                product_type: product,
                type: type,
                money: Number(data.money.replaceAll(".", "").replaceAll(",", ""))
            };
            if (!type) {
                swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
                return;
            }
            setLoad(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/casino/transfer`, formData)
                .then((res) => {
                    if (type == "2") {
                        swal("Thành công", `Chuyển quỹ ra khỏi sảnh thành công!`, "success").then(() => window.location.reload());
                    } else {
                        swal("Thành công", `Chuyển quỹ vào sảnh thành công!`, "success").then(() => window.location.reload());
                    }
                    axios
                        .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                        .then((res) => {
                            setProfile(res.data.data);
                        })
                        .catch((err) => localStorage.removeItem("user"));
                    setLoad(false);
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                    setLoad(false);
                });
        }
    };
    const selectAll = (e) => {
        if (type == 1) {
            setNewMoney(profile.money > 0 && Math.floor(profile.money).toLocaleString("vi-VN"));
        } else if (type == 2) {
            balance > 0 && setNewMoney(Math.floor(balance).toLocaleString("vi-VN"));
        } else {
            swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
        }
    };
    const openGame = () => {
        window.location.href = playGame;
    };
    const menuGame = (title, product_type, game_type) => {
        setTitle(title);
        setLoading(true);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/getgamelist`, { product_type, game_type })
            .then((res) => {
                if (res.data.data.length == 0) {
                    swal("Thông báo", "Sảnh game bảo trì", "error");
                    setLoading(false);
                } else {
                    setGame(res.data.data[0]);
                    setLoading(false);
                    setShow(true);
                }
            })
            .catch((err) => {
                swal("Thông báo", "Sảnh game bảo trì", "error");
                setLoading(false);
            });
    };
    return (
        <>
            <Header profile={profile} />
            {isLoading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="bg-all">
                <div className="container">
                    <div className="tab_item">
                        <div className={activeOption === "0" ? "tabitem active" : "tabitem"}>
                            <div className="content-box">
                                <div className="third-container__title sport">SABA Sports</div>
                                <p className="third-container__p">
                                    Đa dạng hình thức đặt cược, trải nghiệm đặt cược tốt nhất, hỗ trợ rút tiền trước và nhận lãi nhanh hơn một bước, nghĩ những gì bạn muốn và cược
                                    những gì bạn thích.
                                </p>
                                <a class="play-btn" onClick={() => launchGame({ product_type: "174", game_code: "SB0001" })}>
                                    Vào chơi
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/img-sp4.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "1" ? "tabitem active" : "tabitem"}>
                            <div className="content-box">
                                <div className="third-container__title sport">Sportsbook</div>
                                <p className="third-container__p">
                                    Nhà phát triển độc quyền, tỷ lệ cược tốt nhất toàn thị trường, đầy đủ các trận đấu sự kiện, bao gồm trận đấu thể thao phổ biến, cung cấp video
                                    trực tiếp, số liệu thống kê thể thao chuyên nghiệp, để đáp ứng mọi nhu cầu của các trận đấu thể thao!
                                </p>
                                <a class="play-btn" onClick={() => launchGame({ product_type: "54", game_code: "SBO041" })}>
                                    Vào chơi
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/img-sp2.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "2" ? "tabitem active" : "tabitem"}>
                            <div className="content-box">
                                <div className="third-container__title sport">FB Sports</div>
                                <p className="third-container__p">
                                    Bóng đá SBO cung cấp dịch vụ chất lượng cao nhất cho người hâm mộ, bao gồm một loạt sự kiện thể thao đa dạng như NBA, SBL, UEFA Champions
                                    League, Wimbledon, đua ngựa và nhiều hơn nữa.
                                </p>
                                <a class="play-btn" onClick={() => launchGame({ product_type: "142", game_code: "FB0001" })}>
                                    Vào chơi
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/img-sp3.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "3" ? "tabitem active" : "tabitem"}>
                            <div className="content-box">
                                <div className="third-container__title sport">CMD368</div>
                                <p className="third-container__p">
                                    Sảnh cược CMD368 Thể Thao chính là một trong những sản phẩm được thương hiệu xây dựng và phát triển được nhiều người tin tưởng lựa chọn.
                                </p>
                                <a class="play-btn" onClick={() => launchGame({ product_type: "104", game_code: "CMD001" })}>
                                    Vào chơi
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/img-sp5.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "4" ? "tabitem active" : "tabitem"}>
                            <div className="content-box">
                                <div className="third-container__title sport">United Gaming</div>
                                <p className="third-container__p">
                                    Với tính tiện lợi và an toàn của các giải pháp nhà cung cấp mang đến cho người chơi đam mê cá độ trên thế giới, thương hiệu UG thể thao luôn là
                                    sự lựa chọn ưu tiên của dân chơi.
                                </p>
                                <a class="play-btn" onClick={() => launchGame({ product_type: "151", game_code: "UG2001" })}>
                                    Vào chơi
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/img-sp1.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "5" ? "tabitem active" : "tabitem"}>
                            <div className="content-box">
                                <div className="third-container__title sport">BTI</div>
                                <p className="third-container__p">
                                    Bất kể sở thích của bạn là gì, BTi Sports đều có thể đáp ứng nhu cầu của bạn. Bạn có thể tự do đặt cược vào nhiều sự kiện khác nhau, bao gồm
                                    bóng đá, bóng rổ, quần vợt, đua xe F1, Dota 2 và nhiều hơn nữa, mang đến cho bạn trải nghiệm cá cược tuyệt vời nhất!
                                </p>
                                <a class="play-btn" onClick={() => launchGame({ product_type: "47", game_code: "BTI067" })}>
                                    Vào chơi
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/img-sp6.png")} />
                            </div>
                        </div>
                    </div>
                    <div className="tabframe">
                        <div className={activeOption === "0" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("0")}>
                            <img src={require("../../image/common/logo-saba.png")} />
                            <p>SABA</p>
                        </div>
                        <div className={activeOption === "1" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("1")}>
                            <img src={require("../../image/common/logo-sbo.png")} />
                            <p>SBO</p>
                        </div>
                        <div className={activeOption === "2" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("2")}>
                            <img src={require("../../image/common/logo-fb.png")} />
                            <p>FB</p>
                        </div>
                        <div className={activeOption === "3" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("3")}>
                            <img src={require("../../image/common/logo-cmd.png")} />
                            <p>CMD</p>
                        </div>
                        <div className={activeOption === "4" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("4")}>
                            <img src={require("../../image/common/logo-ug.png")} />
                            <p>UG</p>
                        </div>
                        <div className={activeOption === "5" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("5")}>
                            <img src={require("../../image/common/logo-bti.png")} />
                            <p>BTI</p>
                        </div>
                    </div>
                </div>
            </div>
            {playGame && profile ? (
                <div className="popup-backdrop">
                    <div className="popup-main" style={{ position: "relative" }}>
                        <div className="popup-close-top" onClick={() => setPlayGame(null)}>
                            <CloseIcon sx={{ fontSize: "26px", color: "#fff" }} />
                        </div>
                        <div className="popup-header">Sảnh {sanh}</div>
                        <div className="popup-content" style={{ textAlign: "center" }}>
                            <div style={{ color: "#000", marginTop: "20px", fontSize: "20px" }}>
                                Số dư tài khoản: <b>{profile?.money?.toLocaleString("vi-VN")}</b>
                            </div>
                            <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                                <div style={{ color: "#000", fontSize: "20px", margin: "10px 0 20px" }}>
                                    Số dư sảnh {sanh}: {balance?.toLocaleString("vi-VN")}
                                </div>
                                <div style={{ display: "flex", color: "#007aff", justifyContent: "space-around", marginBottom: "10px" }}>
                                    <div>
                                        <input type="radio" name="type_money" value="1" onChange={(e) => setType(1)} />
                                        Chuyển quỹ vào
                                    </div>
                                    <div>
                                        <input type="radio" name="type_money" value="2" onChange={(e) => setType(2)} />
                                        Chuyển quỹ ra
                                    </div>
                                </div>
                                {type == 1 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "18px" }}>
                                        Số tiền nhận được trong game: <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) / 1000).toLocaleString("vi-VN") : 0}</b>
                                    </div>
                                )}
                                {type == 2 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "18px" }}>
                                        Số tiền nhận vào ví chính: <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) * 1000).toLocaleString("vi-VN") : 0}</b>
                                    </div>
                                )}
                                <div className="relative-input" style={{ maxWidth: "400px", margin: "0 auto 20px" }}>
                                    <input
                                        className="ipadd"
                                        type="text"
                                        style={{ width: "100%", borderRadius: "20px", fontSize: "15px", padding: "5px 0 5px 15px", color: "#000" }}
                                        {...register("money", { required: true })}
                                        placeholder="Nhập số tiền"
                                        value={newMoney}
                                        onClick={() => setNewMoney(null)}
                                        onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                    />
                                    <div
                                        className="absolute-input"
                                        onClick={selectAll}
                                        style={{ fontSize: "13px", padding: "10px 20px", height: "90%", top: "5%", right: "2px", borderRadius: "20px" }}>
                                        Tất cả
                                    </div>
                                </div>
                                <button type="submit" className={load === true ? "btn-submit loader" : "btn-submit"} style={{ width: "150px", height: "40px", margin: "0 auto" }}>
                                    Xác nhận
                                </button>
                            </form>
                            <button className="open-game" onClick={openGame}>
                                Vào game ngay
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
export default Sports;
