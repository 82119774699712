import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "./game.css";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";

function Casino() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [isLoading, setLoading] = useState(false);
    const [game, setGame] = useState(null);
    const [title, setTitle] = useState(null);
    const [isShow, setShow] = useState(false);
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);
    function isMobileDevice() {
        if (window.innerWidth < 800) {
            return true;
        }
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            setProfile(res.data.data);
            localStorage.setItem("profile", JSON.stringify(res.data.data));
        });
        handleOptionClick("0");
    }, []);
    const [activeOption, setActiveOption] = useState(null);
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    function launchGame(params) {
        if (localStorage.getItem("profile") == null || localStorage.getItem("profile") == "undefined") {
            navigate("/login");
            return;
        }
        if (isLoading == true) return;
        const form = {
            ...params,
            platform: isMobileDevice == true ? "html5" : "html5-desktop"
        };
        setLoading(true);
        let a;
        switch (params.product_type) {
            case "4":
                a = "AG";
                break;
            case "79":
                a = "BBIN";
                break;
            case "104":
                a = "CMD";
                break;
            case "16":
                a = "CQ9";
                break;
            case "27":
                a = "DG";
                break;
            case "86":
                a = "Evolution";
                break;
            case "142":
                a = "FB";
                break;
            case "140":
                a = "JILI";
                break;
            case "121":
                a = "KingMaker";
                break;
            case "102":
                a = "V8 Poker";
                break;
            case "123":
                a = "King Poker";
                break;
            case "43":
                a = "MG";
                break;
            case "39":
                a = "PP";
                break;
            case "174":
                a = "SABA";
                break;
            case "118":
                a = "WM";
                break;
            case "112":
                a = "SEXY";
                break;
            case "54":
                a = "SBO";
                break;
            case "151":
                a = "UG";
                break;
            case "99":
                a = "TF";
                break;
            case "111":
                a = "Playtech";
                break;
            case "28":
                a = "AllBet";
                break;
            case "55":
                a = "JDB";
                break;
            case "202":
                a = "WS168";
                break;
            case "61":
                a = "IMES";
                break;
            case "132":
                a = "SV388";
                break;
            default:
                a = "";
                break;
        }
        setSanh(a);
        setProduct(params.product_type);
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/launchgame`, form)
            .then((res) => {
                if (res.data.data.status == 0) {
                    setPlayGame(res.data.data.game_url);
                    axios
                        .post(`${process.env.REACT_APP_API_URL}/casino/getbalance`, { product_type: params.product_type })
                        .then((res) => {
                            setBalance(res.data.data.balance);
                            setLoading(false);
                        })
                        .catch((err) => swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error"));
                } else {
                    setLoading(false);
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
            })
            .catch((err) => {
                setLoading(false);
                if (err.response.data.data.tokenVerificationData.access == false) {
                    swal("Đã xảy ra lỗi", "Vui lòng đăng xuất và đăng nhập lại", "error");
                    return;
                }
                swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error");
            });
    }
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    const [playGame, setPlayGame] = useState(null);
    const { register, handleSubmit } = useForm();
    const [newMoney, setNewMoney] = useState(null);
    const [balance, setBalance] = useState(0);
    const [sanh, setSanh] = useState(null);
    const [product, setProduct] = useState(null);
    const [type, setType] = useState(null);
    const [load, setLoad] = useState(false);
    const onSubmit = (data) => {
        if (load === false) {
            if (!sanh) {
                swal("Thông báo", "Vui lòng chọn sảnh", "warning");
                return;
            }
            const formData = {
                product_type: product,
                type: type,
                money: Number(data.money.replaceAll(".", "").replaceAll(",", ""))
            };
            if (!type) {
                swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
                return;
            }
            setLoad(true);
            axios
                .post(`${process.env.REACT_APP_API_URL}/casino/transfer`, formData)
                .then((res) => {
                    if (type == "2") {
                        swal("Thành công", `Chuyển quỹ ra khỏi sảnh thành công!`, "success").then(() => window.location.reload());
                    } else {
                        swal("Thành công", `Chuyển quỹ vào sảnh thành công!`, "success").then(() => window.location.reload());
                    }
                    axios
                        .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                        .then((res) => {
                            setProfile(res.data.data);
                        })
                        .catch((err) => localStorage.removeItem("user"));
                    setLoad(false);
                })
                .catch((err) => {
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error");
                    setLoad(false);
                });
        }
    };
    const selectAll = (e) => {
        if (type == 1) {
            setNewMoney(profile.money > 0 && Math.floor(profile.money).toLocaleString("vi-VN"));
        } else if (type == 2) {
            balance > 0 && setNewMoney(Math.floor(balance).toLocaleString("vi-VN"));
        } else {
            swal("Chú ý", "Vui lòng chọn chuyển tiền vào hoặc chuyển tiền ra", "warning");
        }
    };
    const openGame = () => {
        window.location.href = playGame;
    };
    return (
        <>
            <Header profile={profile} />
            {isLoading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="bg-all">
                <div className="container relative">
                    <div className="tab_item">
                        <div className={activeOption === "0" ? "tabitem active" : "tabitem"}>
                            <div className="content-box">
                                <div className="third-container__title live">WM Casino</div>
                                <p className="third-container__p">
                                    WM Live cung cấp nhiều lựa chọn giải trí video trực tiếp đa dạng, bao gồm baccarat, sic bo, roulette, dragon tiger và nhiều trò chơi khác. Mỗi
                                    trò chơi đều có những đặc điểm riêng, cho phép người chơi tìm được trò chơi phù hợp với sở thích của mình.
                                </p>
                                <a class="play-btn" onClick={() => launchGame({ product_type: "118", game_code: "WM0001" })}>
                                    Vào chơi
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/common/wm.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "1" ? "tabitem active" : "tabitem"}>
                            <div className="content-box">
                                <div className="third-container__title live">MG Casino</div>
                                <p className="third-container__p">
                                    MG live được xây dựng với thông số kỹ thuật cao, trang bị các thiết bị tiên tiến nhất và trang trí lộng lẫy, tạo nên trải nghiệm trò chơi video
                                    thực tế tuyệt vời nhất cho người chơi.
                                </p>
                                <a class="play-btn" onClick={() => launchGame({ product_type: "43", game_code: "MG0001" })}>
                                    Vào chơi
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/common/smglive.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "2" ? "tabitem active" : "tabitem"}>
                            <div className="content-box">
                                <div className="third-container__title live">DG Casino</div>
                                <p className="third-container__p">
                                    Dealer xin đẹp, tương tác trực tiếp, cùng bạn chơi các trò chơi điện tử hiện thực như baccarat, tài xỉu, roulette, Ngầu Hầm, Fried Golden
                                    Flower, đạt tiêu chuẩn quốc tế, công bằng liêm chính, cùng tận hưởng đỉnh cao trong DG live . Vào chơi
                                </p>
                                <a class="play-btn" onClick={() => launchGame({ product_type: "27", game_code: "DG0013" })}>
                                    Vào chơi
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/common/dg.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "3" ? "tabitem active" : "tabitem"}>
                            <div className="content-box">
                                <div className="third-container__title live">AE Casino</div>
                                <p className="third-container__p">
                                    AWC Live rất nhiều lựa chọn trò chơi bao gồm baccarat phổ biến, sic bo, roulette và nhiều hơn nữa, kết hợp với giám sát và quản lý 24/7, cung
                                    cấp cho người chơi một dịch vụ chơi game ổn định và mượt mà.
                                </p>
                                <a class="play-btn" onClick={() => launchGame({ product_type: "112", game_code: "SEX001" })}>
                                    Vào chơi
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/common/awc.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "4" ? "tabitem active" : "tabitem"}>
                            <div className="content-box">
                                <div className="third-container__title live">EVO Casino</div>
                                <p className="third-container__p">
                                    EVO được thiết kế riêng cho người chơi để tạo ra phòng chơi trực tiếp xa hoa nhất, giống như bạn đang ở đó trong thực tế, với các trò chơi tùy
                                    chỉnh không thể cưỡng lại và những cô dealer nữ xinh đẹp nhất và nóng bỏng nhất để tương tác ngay lập tức với bạn. Vào chơi
                                </p>
                                <a class="play-btn" onClick={() => launchGame({ product_type: "86", game_code: "EG0001" })}>
                                    Vào chơi
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/common/evo.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "5" ? "tabitem active" : "tabitem"}>
                            <div className="content-box">
                                <div className="third-container__title live">AG Casino</div>
                                <p className="third-container__p">
                                    AG live video là thương hiệu video trực tuyến tiềm năng nhất ở châu Á, cung cấp Baccarat, Baccarat nhanh, Baccarat Gongmi, baccarat Xổ số, Rồng
                                    hổ, Xóc dĩa, Roulette, Ngầu hầm, Fried Golden Flower v.v. Trò chơi video trực tiếp.
                                </p>
                                <a class="play-btn" onClick={() => launchGame({ product_type: "4", game_code: "A00070" })}>
                                    Vào chơi
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/common/agin.png")} />
                            </div>
                        </div>
                        <div className={activeOption === "6" ? "tabitem active" : "tabitem"}>
                            <div className="content-box">
                                <div className="third-container__title live">SA Casino</div>
                                <p className="third-container__p">
                                    SA Live là một nền tảng giải trí trực tuyến cực mạnh, nơi mọi trò chơi, bao gồm cả baccarat và blackjack, được phát triển tỉ mỉ để mang lại trải
                                    nghiệm chơi game mượt mà, không gián đoạn. Chúng tôi cũng cung cấp các nữ dealer nóng bỏng và sáng tạo nhất để tương tác chất lượng cao với bạn.
                                </p>
                                <a class="play-btn" onClick={() => launchGame({ product_type: "93", game_code: "SA0001" })}>
                                    Vào chơi
                                </a>
                            </div>
                            <div className="pic-box">
                                <img src={require("../../image/common/sa.png")} />
                            </div>
                        </div>
                    </div>
                    <div className="tabframe">
                        <div className={activeOption === "0" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("0")}>
                            <img src={require("../../image/common/icon-wm.png")} />
                            <p>WM CASINO</p>
                        </div>
                        <div className={activeOption === "1" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("1")}>
                            <img src={require("../../image/common/icon-mg.png")} />
                            <p>MG CASINO</p>
                        </div>
                        <div className={activeOption === "2" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("2")}>
                            <img src={require("../../image/common/icon-dg.png")} style={{ transform: "scale(1.2)" }} />
                            <p>DG CASINO</p>
                        </div>
                        <div className={activeOption === "3" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("3")}>
                            <img src={require("../../image/common/icon-awc.png")} />
                            <p>SEXY CASINO</p>
                        </div>
                        <div className={activeOption === "4" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("4")}>
                            <img src={require("../../image/common/icon-evo.png")} />
                            <p>EVO CASINO</p>
                        </div>
                        <div className={activeOption === "5" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("5")}>
                            <img src={require("../../image/common/icon-ag.png")} />
                            <p>AG CASINO</p>
                        </div>
                        <div className={activeOption === "6" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("6")}>
                            <img src={require("../../image/common/icon-sa.png")} />
                            <p>SA CASINO</p>
                        </div>
                    </div>
                </div>
            </div>
            {playGame && profile ? (
                <div className="popup-backdrop">
                    <div className="popup-main" style={{ position: "relative" }}>
                        <div className="popup-close-top" onClick={() => setPlayGame(null)}>
                            <CloseIcon sx={{ fontSize: "26px", color: "#fff" }} />
                        </div>
                        <div className="popup-header">Sảnh {sanh}</div>
                        <div className="popup-content" style={{ textAlign: "center" }}>
                            <div style={{ color: "#000", marginTop: "20px", fontSize: "20px" }}>
                                Số dư tài khoản: <b>{profile?.money?.toLocaleString("vi-VN")}</b>
                            </div>
                            <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                                <div style={{ color: "#000", fontSize: "20px", margin: "10px 0 20px" }}>
                                    Số dư sảnh {sanh}: {balance?.toLocaleString("vi-VN")}
                                </div>
                                <div style={{ display: "flex", color: "#007aff", justifyContent: "space-around", marginBottom: "10px" }}>
                                    <div>
                                        <input type="radio" name="type_money" value="1" onChange={(e) => setType(1)} />
                                        Chuyển quỹ vào
                                    </div>
                                    <div>
                                        <input type="radio" name="type_money" value="2" onChange={(e) => setType(2)} />
                                        Chuyển quỹ ra
                                    </div>
                                </div>
                                {type == 1 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "18px" }}>
                                        Số tiền nhận được trong game: <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) / 1000).toLocaleString("vi-VN") : 0}</b>
                                    </div>
                                )}
                                {type == 2 && newMoney != null && (
                                    <div style={{ margin: "10px 0", fontSize: "18px" }}>
                                        Số tiền nhận vào ví chính: <b>{newMoney ? (Number(newMoney.replaceAll(".", "")) * 1000).toLocaleString("vi-VN") : 0}</b>
                                    </div>
                                )}
                                <div className="relative-input" style={{ maxWidth: "400px", margin: "0 auto 20px" }}>
                                    <input
                                        className="ipadd"
                                        type="text"
                                        style={{ width: "100%", borderRadius: "20px", fontSize: "15px", padding: "5px 0 5px 15px", color: "#000" }}
                                        {...register("money", { required: true })}
                                        placeholder="Nhập số tiền"
                                        value={newMoney}
                                        onClick={() => setNewMoney(null)}
                                        onChange={(e) => setNewMoney(Number(e.target.value.replaceAll(".", "").replaceAll(",", "")).toLocaleString("vi-VN"))}
                                    />
                                    <div
                                        className="absolute-input"
                                        onClick={selectAll}
                                        style={{ fontSize: "13px", padding: "10px 20px", height: "90%", top: "5%", right: "2px", borderRadius: "20px" }}>
                                        Tất cả
                                    </div>
                                </div>
                                <button type="submit" className={load === true ? "btn-submit loader" : "btn-submit"} style={{ width: "150px", height: "40px", margin: "0 auto" }}>
                                    Xác nhận
                                </button>
                            </form>
                            <button className="open-game" onClick={openGame}>
                                Vào game ngay
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
export default Casino;
