import { Link, useNavigate } from "react-router-dom";
import "./home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import Header from "../components/Header";

function Home() {
    SwiperCore.use([Autoplay]);
    const swiperParams = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    };
    const swiperBanner = {
        autoplay: {
            delay: 3000
        },
        slidesPerView: 3,
        spaceBetween: 15,
        grabCursor: true
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
        axios.post(`${process.env.REACT_APP_API_URL}/casino/create`, {});
    }, []);
    return (
        <>
            <Header profile={profile} />
            {loading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            {swiperParams && notify && (
                <div className="box-slide" style={{ overflow: "hidden" }}>
                    <Swiper {...swiperParams}>
                        {notify.map((item, index) => (
                            <>
                                {index > 1 && item.title !== "marquee" && item.title !== "popup" && item.isShow === true ? (
                                    <SwiperSlide>
                                        <img alt={item.title.replace("}", "").replace("{", "")} src={item.image} />
                                    </SwiperSlide>
                                ) : null}
                            </>
                        ))}
                    </Swiper>
                </div>
            )}

            {notify ? (
                <>
                    {notify[0].isShow === true && notify[0].title === "marquee" ? (
                        <div className="header-notify">
                            <div className="container d-flex">
                                <div className="notify-title">
                                    <i className="icon_news"></i>
                                    Thông báo
                                </div>
                                <marquee>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: notify[0].content
                                        }}
                                    />
                                </marquee>
                            </div>
                        </div>
                    ) : null}
                </>
            ) : null}
            <div className="bg-home">
                <div className="container">
                    <div className="flex-home">
                        <div className="banner-left" style={{ width: "60%" }}>
                            <Swiper {...swiperBanner}>
                                <SwiperSlide>
                                    <Link to="/promotion">
                                        <img src="https://upload.onbet998.com/p/20240430/cx_sports/4/337157/png/1714477577219.png" />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to="/promotion">
                                        <img src="https://upload.onbet998.com/p/20240224/cx_sports/4/1033579/png/1708761210253.png" />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to="/promotion">
                                        <img src="https://upload.onbet998.com/p/20240410/cx_sports/4/337157/png/1712728482927.png" />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to="/promotion">
                                        <img src="https://upload.onbet998.com/p/20240516/cx_sports/4/1033579/png/1715835149140.png" />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to="/promotion">
                                        <img src="https://upload.onbet998.com/p/20240113/cx_sports/4/1033579/png/1705146875832.png" />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to="/promotion">
                                        <img src="https://upload.onbet998.com/p/20240715/cx_sports/4/1033579/png/1721020112606.png" />
                                    </Link>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Link to="/promotion">
                                        <img src="https://upload.onbet998.com/p/20240715/cx_sports/4/1033579/png/1721020128476.png" />
                                    </Link>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                        <div className="banner-right" style={{ width: "37%" }}>
                            <Link to="/promotion">
                                <img src="https://upload.onbet998.com/p/20240715/cx_sports/4/1033579/png/1721020048781.png" />
                            </Link>
                            <Link to="/promotion">
                                <img src="https://upload.onbet998.com/p/20231020/cx_sports/4/1033579/png/1697808911569.png" />
                            </Link>
                        </div>
                    </div>
                    <div className="entertaiment">
                        <div className="home-popular__sub">online entertainment</div>
                        <h2 className="home-popular__title"> GIẢI TRÍ PHỔ BIẾN </h2>
                        <img src={require("../../image/glow.png")} style={{ height: "80px", width: "auto", margin: "-15px 0" }} />
                    </div>
                    <div className="flex-home">
                        <div className="home-popular__game">
                            <div className="relative image  ">
                                <img src={require("../../image/model-cgsp.png")} />
                            </div>
                            <div className="text">
                                <div class="rebate__wrap">
                                    <div class="header-rebate-tag">
                                        <span class="rebate-txt">Hoàn trả tối đa</span>
                                        <span class="rebate-num">1.5%</span>
                                    </div>
                                </div>
                                <h3>Thể Thao</h3>
                                <p>
                                    Hơn 50.000 sự kiện, nhiều trò chơi và tỷ lệ chấp khác nhau, cũng như đặt cược theo lịch trình và thanh toán sớm, mang đến cho bạn sự thích thú
                                    đặc biệt.
                                </p>
                                <button onClick={() => navigate("/sports")}>Tham gia</button>
                            </div>
                        </div>
                        <div className="home-popular__game">
                            <div className="relative image  ">
                                <img src={require("../../image/model-cglive.png")} />
                            </div>
                            <div className="text">
                                <div class="rebate__wrap">
                                    <div class="header-rebate-tag">
                                        <span class="rebate-txt">Hoàn trả tối đa</span>
                                        <span class="rebate-num">2.5%</span>
                                    </div>
                                </div>
                                <h3>Casino</h3>
                                <p>
                                    Có Baccarat, Ngầu Hầm, Rồng Hổ, Fried Golden Flower, Xóc Dĩa, Fan Tan và các bàn trò chơi khác để bạn lựa chọn. Đại lý trực tiếp HD gợi cảm sẽ
                                    chơi với bạn!
                                </p>
                                <button onClick={() => navigate("/casino")}>Tham gia</button>
                            </div>
                        </div>
                    </div>
                    <div className="flex-home" style={{ margin: "20px 0", gap: "15px" }}>
                        <div className="home-popular__game-third">
                            <img src={require("../../image/model-qp.png")} />
                            <div class="rebate__wrap">
                                <div class="header-rebate-tag">
                                    <span class="rebate-txt">Hoàn trả tối đa</span>
                                    <span class="rebate-num">1.5%</span>
                                </div>
                            </div>
                            <h3>GAME BÀI</h3>
                            <button onClick={() => navigate("/pvp")}>Tham gia</button>
                        </div>
                        <div className="home-popular__game-third">
                            <img src={require("../../image/model-dz.png")} />
                            <div class="rebate__wrap">
                                <div class="header-rebate-tag">
                                    <span class="rebate-txt">Hoàn trả tối đa</span>
                                    <span class="rebate-num">2%</span>
                                </div>
                            </div>
                            <h3>SLOT GAME</h3>
                            <button onClick={() => navigate("/slots")}>Tham gia</button>
                        </div>
                        <div className="home-popular__game-third">
                            <img src={require("../../image/model-cp.png")} style={{ width: "114%", maxWidth: "114%", marginLeft: "-7%" }} />
                            <div class="rebate__wrap"></div>
                            <h3>XỔ SỐ</h3>
                            <button onClick={() => navigate("/lottery")}>Tham gia</button>
                        </div>
                        <div className="home-popular__game-third">
                            <img src={require("../../image/model-es.png")} />
                            <div class="rebate__wrap">
                                <div class="header-rebate-tag">
                                    <span class="rebate-txt">Hoàn trả tối đa</span>
                                    <span class="rebate-num">1.5%</span>
                                </div>
                            </div>
                            <h3>ESPORTS</h3>
                            <button onClick={() => navigate("/esports")}>Tham gia</button>
                        </div>
                        <div className="home-popular__game-third">
                            <img src={require("../../image/model-by.png")} />
                            <div class="rebate__wrap">
                                <div class="header-rebate-tag">
                                    <span class="rebate-txt">Hoàn trả tối đa</span>
                                    <span class="rebate-num">2%</span>
                                </div>
                            </div>
                            <h3>BẮN CÁ</h3>
                            <button onClick={() => navigate("/fishing")}>Tham gia</button>
                        </div>
                        <div className="home-popular__game-third">
                            <img src={require("../../image/model-dj.png")} />
                            <div class="rebate__wrap">
                                <div class="header-rebate-tag">
                                    <span class="rebate-txt">Hoàn trả tối đa</span>
                                    <span class="rebate-num">1.5%</span>
                                </div>
                            </div>
                            <h3>ĐÁ GÀ</h3>
                            <button onClick={() => navigate("/cockfighting")}>Tham gia</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Home;
