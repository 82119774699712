import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import "./game.css";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { GetCountry, GetImage, GetDate } from "../../funcUtils";

function Lottery() {
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [profile, setProfile] = useState(null);
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
    }, []);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const date0 = new Date();
    const currentHour = date0.getHours();
    const [dateMT, setDateMT] = useState(date0.getDay());
    const [dataMT, setDataMT] = useState();
    const [imageMT, setImageMT] = useState([]);
    const [dateMN, setDateMN] = useState(date0.getDay());
    const [dataMN, setDataMN] = useState([]);
    const [imageMN, setImageMN] = useState([]);
    const [activeOption, setActiveOption] = useState("0");
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    const [activeOption1, setActiveOption1] = useState(date0.getDay());
    const handleOptionClick1 = (option) => {
        setActiveOption1(option);
        setDateMT(option);
    };
    const [activeOption2, setActiveOption2] = useState(date0.getDay());
    const handleOptionClick2 = (option) => {
        setActiveOption2(option);
        setDateMN(option);
    };
    useEffect(() => {
        if (dateMT == 0) {
            if (currentHour < 18) {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            } else {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            }
        }
        if (dateMT == 1) {
            if (currentHour < 18) {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            } else {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            }
        }
        if (dateMT == 2) {
            if (currentHour < 18) {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            } else {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            }
        }
        if (dateMT == 3) {
            if (currentHour < 18) {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            } else {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            }
        }
        if (dateMT == 4) {
            if (currentHour < 18) {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            } else {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            }
        }
        if (dateMT == 5) {
            if (currentHour < 18) {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            } else {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            }
        }
        if (dateMT == 6) {
            if (currentHour < 18) {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            } else {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            }
        }
    }, [dateMT]);
    useEffect(() => {
        if (dateMN == 0) {
            if (currentHour < 17) {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            } else {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            }
        }
        if (dateMN == 1) {
            if (currentHour < 17) {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            } else {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            }
        }
        if (dateMN == 2) {
            if (currentHour < 17) {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            } else {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            }
        }
        if (dateMN == 3) {
            if (currentHour < 17) {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            } else {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            }
        }
        if (dateMN == 4) {
            if (currentHour < 17) {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            } else {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            }
        }
        if (dateMN == 5) {
            if (currentHour < 17) {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            } else {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            }
        }
        if (dateMN == 6) {
            if (currentHour < 17) {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            } else {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            }
        }
    }, [dateMN]);
    return (
        <>
            <Header profile={profile} />
            {loading == true ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <div className="bg-all" style={{ padding: "0 0 20px" }}>
                <div className="container" style={{ maxWidth: "1200px" }}>
                    <div className="tab_item" style={{ height: "320px" }}>
                        <div className="tabitem active" style={{ height: "320px" }}>
                            <div className="content-box" style={{ width: "45%" }}>
                                <div className="third-container__title lottery">Xổ Số</div>
                                <p className="third-container__p">
                                    Được phát triển độc quyền với các trò chơi xổ số cổ điển và sáng tạo, sẽ tăng thêm độ phấn khích cho quá trình chơi của bạn!
                                </p>
                            </div>
                            <div className="pic-box" style={{ width: "55%" }}>
                                <img src={require("../../image/img-bg-lottery.png")} />
                            </div>
                        </div>
                    </div>
                    <div className="tabframe" style={{ margin: "0" }}>
                        <div className={activeOption === "0" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("0")}>
                            <img src={require("../../image/common/north-hover.png")} style={{ padding: "5px" }} />
                            <p>Miền Bắc</p>
                        </div>
                        <div className={activeOption === "1" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("1")}>
                            <img src={require("../../image/common/center-hover.png")} style={{ padding: "5px" }} />
                            <p>Miền Trung</p>
                        </div>
                        <div className={activeOption === "2" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("2")}>
                            <img src={require("../../image/common/south-hover.png")} style={{ padding: "5px" }} />
                            <p>Miền Nam</p>
                        </div>
                        <div className={activeOption === "3" ? "tabframeitem active" : "tabframeitem"} onClick={() => handleOptionClick("3")}>
                            <img src={require("../../image/common/st-hover.png")} style={{ padding: "5px" }} />
                            <p>Siêu Tốc</p>
                        </div>
                    </div>
                    <div className="box-card-game" style={{ marginTop: "20px" }}>
                        {activeOption === "0" && (
                            <div className="grid-5">
                                <div className="card-game card_bg">
                                    <div className="card-game-image">
                                        <img src={require(`../../images/xs3mien/mienbac.png`)} />
                                    </div>
                                    <div className="card-game-info">
                                        <div className="card-game-title">Miền Bắc</div>
                                        <div onClick={() => navigate("/xsmb/lo2")} className="card-game-play">
                                            Vào trò chơi
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {activeOption === "1" && (
                            <>
                                <ul className="tab-navigation tab-game tab-xoso">
                                    <li className={activeOption1 === 1 ? "active" : ""} onClick={() => handleOptionClick1(1)}>
                                        Thứ 2
                                    </li>
                                    <li className={activeOption1 === 2 ? "active" : ""} onClick={() => handleOptionClick1(2)}>
                                        Thứ 3
                                    </li>
                                    <li className={activeOption1 === 3 ? "active" : ""} onClick={() => handleOptionClick1(3)}>
                                        Thứ 4
                                    </li>
                                    <li className={activeOption1 === 4 ? "active" : ""} onClick={() => handleOptionClick1(4)}>
                                        Thứ 5
                                    </li>
                                    <li className={activeOption1 === 5 ? "active" : ""} onClick={() => handleOptionClick1(5)}>
                                        Thứ 6
                                    </li>
                                    <li className={activeOption1 === 6 ? "active" : ""} onClick={() => handleOptionClick1(6)}>
                                        Thứ 7
                                    </li>
                                    <li className={activeOption1 === 0 ? "active" : ""} onClick={() => handleOptionClick1(0)}>
                                        Chủ nhật
                                    </li>
                                </ul>
                                <div className="grid-5">
                                    {dataMT?.map((item, index) => (
                                        <div className="card-game card_bg">
                                            <div className="card-game-image">
                                                <img src={require(`../../images/xs3mien/${imageMT[index]}.png`)} />
                                            </div>
                                            <div className="card-game-info">
                                                <div className="card-game-title">{GetCountry(item)}</div>
                                                <div onClick={() => navigate(`/xsmt/lo2/${item}`)} className="card-game-play">
                                                    Vào trò chơi
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        {activeOption === "2" && (
                            <>
                                <ul className="tab-navigation tab-game tab-xoso">
                                    <li className={activeOption2 === 1 ? "active" : ""} onClick={() => handleOptionClick2(1)}>
                                        Thứ 2
                                    </li>
                                    <li className={activeOption2 === 2 ? "active" : ""} onClick={() => handleOptionClick2(2)}>
                                        Thứ 3
                                    </li>
                                    <li className={activeOption2 === 3 ? "active" : ""} onClick={() => handleOptionClick2(3)}>
                                        Thứ 4
                                    </li>
                                    <li className={activeOption2 === 4 ? "active" : ""} onClick={() => handleOptionClick2(4)}>
                                        Thứ 5
                                    </li>
                                    <li className={activeOption2 === 5 ? "active" : ""} onClick={() => handleOptionClick2(5)}>
                                        Thứ 6
                                    </li>
                                    <li className={activeOption2 === 6 ? "active" : ""} onClick={() => handleOptionClick2(6)}>
                                        Thứ 7
                                    </li>
                                    <li className={activeOption2 === 0 ? "active" : ""} onClick={() => handleOptionClick2(0)}>
                                        Chủ nhật
                                    </li>
                                </ul>
                                <div className="grid-5">
                                    {dataMN?.map((item, index) => (
                                        <div className="card-game card_bg">
                                            <div className="card-game-image">
                                                <img src={require(`../../images/xs3mien/${imageMN[index]}.png`)} />
                                            </div>
                                            <div className="card-game-info">
                                                <div className="card-game-title">{GetCountry(item)}</div>
                                                <div onClick={() => navigate(`/xsmn/lo2/${item}`)} className="card-game-play">
                                                    Vào trò chơi
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        {activeOption === "3" && (
                            <div className="grid-5">
                                <div className="card-game card_bg">
                                    <div className="card-game-image">
                                        <img src={require("../../images/mb75s.png")} />
                                    </div>
                                    <div className="card-game-info">
                                        <div className="card-game-title">Xổ số nhanh 75s</div>
                                        <div onClick={() => navigate("/xs75s/lo2")} className="card-game-play">
                                            Vào trò chơi
                                        </div>
                                    </div>
                                </div>
                                <div className="card-game card_bg">
                                    <div className="card-game-image">
                                        <img src={require("../../images/st2p.png")} />
                                    </div>
                                    <div className="card-game-info">
                                        <div className="card-game-title">Xổ số nhanh 120s</div>
                                        <div onClick={() => navigate("/xs120s/lo2")} className="card-game-play">
                                            Vào trò chơi
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
export default Lottery;
